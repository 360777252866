import { createInertiaApp } from '@inertiajs/vue3'
import * as SentrySDK from '@sentry/vue'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { ZiggyVue } from 'ziggy-js'
import { Ziggy } from './ziggy.js'
import { createPinia } from 'pinia'
import { createApp, h } from 'vue'
import { LaravelEcho } from './Plugins/Echo'
import { inertiaRoutingInstrumentation } from './Plugins/Sentry'
// import { Swiper } from './Plugins/Swiper'
import 'vue3-toastify/dist/index.css'

createInertiaApp({
    progress: {
        color: '#F5A823',
        includeCSS: false,
        showSpinner: false,
    },
    title: (title) => `${title} - Clipfinder.com`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(createPinia())
            .use(LaravelEcho)
            //.use(Swiper)
            .use(ZiggyVue, Ziggy)

        SentrySDK.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC || null,
            tracesSampleRate: 0.1,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
            integrations: [
                SentrySDK.browserTracingIntegration({
                    routingInstrumentation: inertiaRoutingInstrumentation,
                }),
                SentrySDK.replayIntegration(),
            ],
        })

        app.mount(el)

        return app
    },
})
